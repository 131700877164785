<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>
                <router-link :to="{ name: 'company.gift.list' }">
                  {{ $t("gift.management") }}
                </router-link>
              </li>
              <li>{{ $t("gift.edit") }}</li>
            </ul>
          </nav>
        </div>
        <div class="card" v-if="is_loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
          <template v-if="errorMessages">
            <div
              v-for="(error, index) in errorMessages"
              :key="index"
              class="uk-alert-danger"
              uk-alert
            >
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
          </template>
          <div v-if="successMessage" class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: green">
              <i class="uil-exclamation-triangle"></i> {{ successMessage }}
            </p>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <h4><i class="uil-gift"></i> {{ $t("gift.edit") }}</h4>
              <hr />
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <form action="">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >{{ $t("gift.name") }}
                        <span class="required">*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          value=""
                          :placeholder="$t('gift.enter_name')"
                          required=""
                          v-model="item.name"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label
                        class="col-md-3 col-form-label"
                        for="short_description"
                        >{{ $t("general.description") }}</label
                      >
                      <div class="col-md-9">
                        <textarea
                          name="short_description"
                          id="short_description"
                          class="form-control"
                          v-model="item.description"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="point"
                        >{{ $t("point.coin") }}
                        <span class="required">*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          type="number"
                          class="form-control"
                          id="point"
                          name="point"
                          v-model="item.score"
                          :placeholder="$t('point.enter_coin')"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="stock_quantity"
                      >{{ $t("point.stock_quantity") }} <span class="required">*</span></label
                      >
                      <div class="col-md-9">
                        <input
                            type="number"
                            class="form-control"
                            id="stock_quantity"
                            name="stock_quantity"
                            v-model="item.stock_quantity"
                            required=""
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="status">
                        {{ $t("general.status") }}</label
                      >
                      <div class="col-md-9">
                        <toggle-button
                          :width="80"
                          v-model="is_status"
                          color="#ae71ff"
                          :sync="true"
                          :labels="{
                            checked: $t('general.active'),
                            unchecked: $t('general.passive'),
                          }"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="is_address">{{
                        $t("gift.is_address_required")
                      }}</label>
                      <div class="col-md-9">
                        <toggle-button
                          :width="80"
                          v-model="is_address"
                          color="#ae71ff"
                          :sync="true"
                          :labels="{
                            checked: $t('general.yes'),
                            unchecked: $t('general.no'),
                          }"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="logo"
                        >{{ $t("gift.avatar")
                        }}<span class="required">*</span></label
                      >
                      <div class="col-md-9">
                        <div
                          class="
                            position-relative
                            d-flex
                            justify-center justify-content-center
                          "
                        >
                          <img
                            style="height: 190px"
                            class="rounded img-fluid logoarea"
                            :src="item.image_link"
                            alt=""
                          />
                          <input
                            type="file"
                            class="d-none"
                            id="avatar"
                            accept="image/*"
                            @change="processFile($event)"
                          />
                          <button
                            @click="inputClick()"
                            type="button"
                            class="btn btn-default btn-icon-label"
                            style="top: 50%; position: absolute"
                          >
                            <span class="btn-inner--icon">
                              <i class="uil-image"></i>
                            </span>
                            <span class="btn-inner--text">{{
                              $t("general.image_change")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <router-link
                        class="uk-button-default mr-2 uk-button small"
                        :to="{ name: 'company.gift.list' }"
                      >
                        {{ $t("general.back") }}
                      </router-link>
                      <button
                        class="uk-button uk-button-primary small"
                        type="button"
                        @click="saveForm"
                      >
                        <i class="icon-feather-arrow-right"></i>
                        {{ $t("general.save") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import gift_module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEM,
  GET_ITEM,
  CREATE_ITEM,
} from "@/core/services/store/gift.module";
export default {
  name: "GiftEdit",
  components: { DataTable,DefaultLoading },
  data() {
    return {
      is_loading:false,
      successMessage: null,
      errorMessages: [],
      is_status: false,
      is_address: false,
      image_updated: false,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, gift_module);
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {},
    },
  },
  methods: {
    getItem() {
      store
        .dispatch(MODULE_NAME + "/" + GET_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
        }).then(()=>{
          this.is_address = this.item.address_required ? true : false
          this.is_status = this.item.status_id ? true :false
        })
    },
    saveForm() {
      let form = this.item;
      let formData = new FormData();
      this.errorMessages = [];
      this.is_loading = true;
      if (form.name.length === 0) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("gift.name")
          )
        );
      this.is_loading = false;
        return false;
      }
      if (form.score < 1) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("point.point")
          )
        );
      this.is_loading = false;
        return false;
      }
        if (!form.image) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("gift.avatar")
          )
        );
      this.is_loading = false;
        return false;
      }
      formData.append("name", form.name);
      formData.append("description", form.description);
      formData.append("score", form.score);
      formData.append("stock_quantity", form.stock_quantity);
      formData.append("status_id", this.is_status ? 1 : 0);
      formData.append("address_required", this.is_address ? 1 : 0);
      formData.append("image", this.image_updated ?  form.image : null);

      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id + "/update",
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_updated");
            this.$router.push({ name: "company.gift.list" });
          }else this.errorMessages.push(this.error)
        this.is_loading = false;
        })
    },
    processFile(event) {
      this.image_updated = true
      this.item.image = event.target.files[0];
      let blob = URL.createObjectURL(this.item.image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#avatar").click();
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
  },
  mounted() {
    if(!this.$isGiftVisible){
      this.$router.push({name:'company.dashboard'})
    }
    this.getItem()
  },
  watch: {
    error(){
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>